import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'

import Form from './Form'
import Success from './Success'

render(<BrowserRouter>
	<div>
		<Route exact path="/" component={Form} />
		<Route exact path="/thanks" component={Success} />
	</div>
</BrowserRouter>, document.getElementById('root'))