import React from 'react'
import Layout from './Layout'

export default class Success extends React.Component {

	render() {
		return (
			<Layout>
				<div className="text-center">
					<h3>Dziękujemy za rejestrację!</h3>
					<p>Płatność została wykonana prawidłowo. Wkrótce na podany w formularzu adres wyślemy Ci wiadomość e-mail z zakupiony biletami.</p>
				</div>
			</Layout>
		)
	}
}