import React from 'react'
import Layout from './Layout'
import { Form, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { StickyContainer, Sticky } from 'react-sticky';
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { animateScroll, Element } from 'react-scroll'
import { findDOMNode } from 'react-dom'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const onSubmit = values => new Promise(resolve => {

	axios.post('https://greggbraden.myevent.pl/register.php', values).then((response) => {
		window.location.assign(response.data.url)
		resolve()
	}).catch((err) => {
		resolve(err.response.data)
		setTimeout(() => {
			let body = findDOMNode(document.querySelector('body')).getBoundingClientRect()
			let firstError = findDOMNode(document.querySelector('.error')).getBoundingClientRect()
			animateScroll.scrollTo(firstError.top - body.top - 100);
		}, 300);
	})
})

export default class App extends React.Component {

	state = {
		loaded: false,
		imie: '',
		potwierdzenie: false,
		przyjezdny: false,
		nocleg: false
	}

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const hash = urlParams.get('hash');

		axios.get('https://wigiliawebdev2018.myevent.pl/getState.php?hash=' + hash).then((response) => {
			this.setState({
				loaded: true,
				imie: response.data.imie,
				potwierdzenie: response.data.potwierdzenie,
				przyjezdny: response.data.przyjezdny
			})
		})
	}

	confirm() {
		const urlParams = new URLSearchParams(window.location.search);
		const hash = urlParams.get('hash');
		axios.post('https://wigiliawebdev2018.myevent.pl/getState.php?hotel='+(this.state.nocleg ? 1 : 0)+'&confirm=1&hash=' + hash, {}).then((response) => {
			this.setState({
				loaded: true,
				potwierdzenie: 'Tak'
			})
		})
	}

	reject() {
		const urlParams = new URLSearchParams(window.location.search);
		const hash = urlParams.get('hash');
		axios.post('https://wigiliawebdev2018.myevent.pl/getState.php?hotel=0&hash=' + hash, {}).then((response) => {
			this.setState({
				loaded: true,
				potwierdzenie: 'Nie'
			})
		})
	}

	render() {
		const Form = () => (
			<div className="col-md-12" style={{textAlign: 'center'}}>
				<h1 style={{textAlign: 'center'}}><b>{this.state.imie}</b>, zapraszamy Cię na firmowe spotkanie wigilijne! :)</h1>
				<h2 style={{textAlign: 'center'}}><b>Gdzie:</b> Restauracja Paros przy ul. Jasnej 14/16A w Warszawie</h2>
				<h2 style={{textAlign: 'center'}}><b>Kiedy:</b> Czwartek, 20 grudnia, godz. 17:30</h2>

				{ this.state.przyjezdny && (
				<label onClick={() => this.setState({nocleg: !this.state.nocleg})}>
					<div className={"checkbox" + (this.state.nocleg ? ' active':'')}></div>
					Potrzebuję noclegu
				</label>)}

				<button className="potwierdzam" onClick={() => this.confirm()}>Potwierdzam obecność</button>
				<button className="rezygnuje" onClick={() => this.reject()}>Niestety, mnie nie będzie :(</button>
			</div>
		)

		const Thanks = () => (
			<div className="col-md-12" style={{textAlign: 'center'}}>
				<h1 style={{textAlign: 'center'}}><b>{this.state.imie}</b>, dziękujemy.<br/>Super, że z nami będziesz :)<br/><br/>Do zobaczenia niebawem!</h1>
			</div>
		)

		const Trudno = () => (
			<div className="col-md-12" style={{textAlign: 'center'}}>
				<h1 style={{textAlign: 'center'}}><b>{this.state.imie}</b>, szkoda, że Cie nie będzie :(<br/><br/>Jeżeli zmienisz zdanie, daj znać!</h1>
			</div>
		)

		return (
			<Layout>
				<div className="container" style={{marginTop: '50px'}}>
					<div className="row">						
						{this.state.loaded && (
							<React.Fragment>
							{ this.state.potwierdzenie == 'Tak' && <Thanks /> }
							{ this.state.potwierdzenie == 'Nie' && <Trudno /> }
							{ this.state.potwierdzenie == '' && <Form /> }
							</React.Fragment>
						)}
					</div>
				</div>
			</Layout>
		)
	}
}