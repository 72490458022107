import React from 'react'
import webdevLogo from './logo.png'
import sople from './sople.png'
import snieg from './snieg.png'
import choinka from './choinka.png'
import choinka2 from './choinka2.png'
import Snow from 'react-snow-effect'

export default class Layout extends React.Component {
	render() {
		return (
			<div>
				<Snow />
				<div className="heading">
					<div className="container">
						<h2 className="mb-3">
							<img src={webdevLogo} style={{ zIndex: '999999 !important' }} width={400} />
						</h2>
					</div>
				</div>
				<div className="layout">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								{this.props.children}
							</div>
						</div>
					</div>
				</div>
				<img className="choinka" src={choinka} style={{ position: 'fixed', bottom: 0, transform: 'translate(10%, -20%) scale(0.7)' }} />
				<img className="choinka" src={choinka2} style={{ position: 'fixed', bottom: 0, right: 0, transform: 'translate(-10%, -20%) scale(0.7)' }} />
				<img className="choinka" src={choinka} style={{ position: 'fixed', bottom: 0, transform: 'translate(20%, 20%) scale(0.7)' }} />
				<img className="choinka" src={choinka2} style={{ position: 'fixed', bottom: 0, right: 0, transform: 'translate(-60%, -10%) scale(0.7)' }} />
				
				<div className="snieg"></div>
				<div className="sople"></div>
			</div>
		)
	}
}